import React, { Suspense } from "react";
import Loader from "./layouts/Loader";
import Spinner from "./layouts/Spinner";
import { AppRouting } from "./shared/AppRouting";
import "./assets/css/spacer.css";
import "./assets/css/style.css";
import { ThemeProvider } from "styled-components";
import portalTheme from "./themes/App.theme";
//ashish.
const App: React.FC = () => (
  <>
    <Suspense fallback={<Spinner />}>
      {/* <ToastContainer limit={3} className="toaster" /> */}
      <Loader />
      <ThemeProvider theme={portalTheme}>
        <AppRouting />
      </ThemeProvider>
    </Suspense>
  </>
);

export default App;
